import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NfaService } from './one-pass/nfa.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyAuthGuard implements CanActivate {

  constructor(private router: Router, private nfa:NfaService) {}

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
//       const jwtToken = localStorage.getItem('jwtToken');
      
//       if(jwtToken) {
//         // JWT token is set, allow access to dashboard component
//         return true;
//       } else {
//         // JWT token is not set, redirect to login page
//         this.router.navigate(['/one-pass']);
//         return false;
//       }
//   }
canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.nfa.isAuthenticated().toPromise().then((authenticated: boolean) => {
        if (authenticated) {
          return true;
        } else {
          this.router.navigate(['/one-pass']);
          return false;
        }
      });
  }
}
