import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, Output, EventEmitter, ElementRef, Input,OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, FormControl, Validators } from '@angular/forms';
import { MatStepper, MatTableDataSource } from '@angular/material';
import { Country, State, City } from 'country-state-city';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { css } from 'jquery';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common'
import { BehaviorSubject } from 'rxjs';
import {ErrorStateMatcher} from '@angular/material/core';

import { Platform } from '@angular/cdk/platform';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-med-effect-per-seizure',
  templateUrl: './med-effect-per-seizure.component.html',
  styleUrls: ['./med-effect-per-seizure.component.scss']
})
export class MedEffectPerSeizureComponent implements OnInit {
  @ViewChild('medEffectForvc', {static: true}) medEffectFormvc: FormGroupDirective;
  @Input() seizureType? : string ;
  @Input() patch_obj? : string ;
  @Output() formChanges = new EventEmitter<any>() 
  showInTitle = "tester"
  medEffectForm: FormGroup;

  percentOfReductionArr = ["1-25% reduction", "26-50% reduction", "51-75% reduction", "76-99% reduction", "100% response, with no seizure"]
  howLongArr = [ "1 to 6 months", "6 months to 1 year", "More than 1 year"]
  averageDurationArr = ["Seconds only", "Few seconds to 1 min", "1 to 3 minutes", "3 to 5 minutes", "5 to 30 minutes", "30 minutes to longer"]
  seizureFreqArr = ["Daily multiple times", "Daily to weekly", "Weekly to monthly", "Monthly or less frequent"]
  sideEffectArr = ["Worsening of seizures", "Regression in developmental skills", "Drowsiness/sedation", "Rash", "Balance problems", "Liver enzyme elevation", "Kidney stone", "Urinary retention", "Another type of seizure started","Others"]

  
  constructor(public datepipe: DatePipe, private _formBuilder: FormBuilder, public dialog: MatDialog, private dateAdapter: DateAdapter<Date>, private changeDetectorRefs: ChangeDetectorRef) { 
    this.medEffectForm = this._formBuilder.group({
     
      percent_of_reduction: ['', Validators.required],
      how_long_did_it_work: ['', Validators.required],

      average_duration_of_seizure_before_med: ['', Validators.required],
      average_duration_of_seizure: ['', Validators.required],
      frequency_of_seizure_before_med: ['', Validators.required],
      frequency_of_seizure: ['', Validators.required],

    });
    this.medEffectForm.valueChanges.subscribe(value => {
      if (this.patch_obj && typeof this.patch_obj === 'object') {
        value.id = this.patch_obj!['id']
      }
      this.formChanges.emit({"form_values":value, "seizure_type":this.seizureType});
    });
  }

  ngOnInit() {
    console.log(this.patch_obj);
    if (this.patch_obj && typeof this.patch_obj === 'object') {
      this.medEffectForm = this._formBuilder.group({
        percent_of_reduction: [this.patch_obj!['percent_of_reduction']! || '', Validators.required],
        how_long_did_it_work: [this.patch_obj!['how_long_did_it_work']! || '', Validators.required],
        average_duration_of_seizure_before_med: [this.patch_obj!['average_duration_of_seizure_before_med']! || '', Validators.required],
        average_duration_of_seizure: [this.patch_obj!['average_duration_of_seizure']! || '', Validators.required],
        frequency_of_seizure_before_med: [this.patch_obj!['frequency_of_seizure_before_med']! || '', Validators.required],
        frequency_of_seizure: [this.patch_obj!['frequency_of_seizure']! || '', Validators.required],
      });
      this.medEffectForm.valueChanges.subscribe(value => {
        if (this.patch_obj && typeof this.patch_obj === 'object') {
          value.id = this.patch_obj!['id']
        }
        this.formChanges.emit({"form_values":value, "seizure_type":this.seizureType});
      });

    }
    // ...
  }

}
