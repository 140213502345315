import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';
import { UserProfileData } from './interfaces/data';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class FetcherService {
  public selectedLang:string;
  public userDict: UserProfileData = {};
  // public serverUrl = "https://localhost:443/api/";
  public serverUrl = environment.apiHost;
  public localServerUrl = "https://localhost:3000/api/";

  constructor(private httpClient: HttpClient) { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
  
  setSelectedLang(val:string){
    this.selectedLang = val;
  }

  getSelectedLang(){
    return this.selectedLang;
  }

  setDict(val: UserProfileData) {
    this.userDict = val;
  }

  getDict(): UserProfileData {
    return this.userDict;
  }

  gmailSetLabel(threadId, addLabel, removeLabel){
    let reqObj = {
      threadIds:[threadId],
      addLabel:[addLabel],
      removeLabel:[removeLabel]
    }
    return new Observable(observer => {
      this.httpClient.post(this.serverUrl + 'modifymsg/', reqObj)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  addUserProfile(profDict): Observable< any > {
    return new Observable(observer => {
      console.log(profDict);
      this.httpClient.post(this.serverUrl + 'addprofile/', profDict)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  downLoadFile(filename) {
    const resp = this.httpClient.get(this.serverUrl + 'downloaduserfiles/' + filename).subscribe(res => { console.log(res) })
  }

getFormsFromDrive(){
  return new Observable(observer => {
    let dummy = {"test":"body"}
    this.httpClient.post(this.serverUrl + 'gscript/',dummy)
      .subscribe(res => {
        observer.next(res);
      }, err => {
        observer.error(err);
      });
  });
}

createSurvey(name){
  return new Observable(observer => {
    let nameObj = {"name":name}
    this.httpClient.post(this.serverUrl + 'createsurvey/',nameObj)
      .subscribe(res => {
        observer.next(res);
      }, err => {
        observer.error(err);
      });
  });
}


  getAllProfiles(): Observable< any > {
    return new Observable(observer => {
      let dummy = {"test":"body"}
      this.httpClient.post(this.serverUrl + 'getprofiles/',dummy)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  getFilesFor(profile_id): Observable< any > {
    return new Observable(observer => {
      let profile_id_obj = {"user_profile_id":profile_id}
      this.httpClient.post(this.serverUrl + 'getfilesfor/',profile_id_obj)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  sendMail(mailObj): Observable< any > {
    console.log("in service")
    return new Observable(observer => {
      this.httpClient.post(this.serverUrl + 'sendattachmentmail/',mailObj)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  sendGmail(mailObj): Observable< any > {
    console.log("in service")
    return new Observable(observer => {
      this.httpClient.post(this.serverUrl + 'sendgmail/',mailObj)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  getGmail(label): Observable< any > {
    var query = {"label":label}
    return new Observable(observer => {
      this.httpClient.post(this.serverUrl + 'getgmails/',query)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  getSnippets(threadArrObj): Observable< any > {
    var mailObj = {}
    return new Observable(observer => {
      this.httpClient.post(this.serverUrl + 'getsnippets/',threadArrObj)
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  // anti-seizure surveys list
  createAntiSeizureSurvey(surveyData): Observable<any> {
    return this.httpClient.post(this.serverUrl + 'antiseizure/survey', surveyData);
  }

  // Service function to get all surveys for a given email ID
  getSurveysByEmail(emailId): Observable<any> {
    return this.httpClient.get(this.serverUrl + 'antiseizure/survey/' + emailId);
  }

  
  getGeneralFeaturesBySurveyId(surveyId: string): Observable<any> {
    return this.httpClient.get<any>(this.serverUrl + 'antiseizure/general-features/' + surveyId);
  }

  updateOrCreateGeneralFeatures(surveyId: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this.serverUrl + 'antiseizure/general-features/' + surveyId, data);
  }

  updateOrCreateCombinedMedicalHistory(surveyId: number, data: any): Observable<any> {
    const url = `${this.serverUrl}antiseizure/combined-medical-history/${surveyId}`;
    return this.httpClient.post<any>(url, data);
  }

  getCombinedMedicalHistory(surveyId: number): Observable<any> {
    const url = `${this.serverUrl}antiseizure/combined-medical-history/${surveyId}`;
    return this.httpClient.get<any>(url);
  }

  //deletes the effects too due to cascade 
  deleteMedicalHistory(medHistRowId: number): Observable<any> {
    const url = `${this.serverUrl}antiseizure/combined-medical-history/${medHistRowId}`;
    return this.httpClient.delete<any>(url);
  }

  createOrUpdateNonMedForSurveyId(surveyId: string, data: any): Observable<any> {
    const url = `${this.serverUrl}antiseizure/non-medical-history/${surveyId}`;
    return this.httpClient.post<any>(url, data);
  }
  
  deleteNonMedForId(nmId: string): Observable<any> {
    const url = `${this.serverUrl}antiseizure/non-medical-history/${nmId}`;
    return this.httpClient.delete<any>(url);
  }

  getNonMedsForSurveyId(surveyId: string): Observable<any> {
    const url = `${this.serverUrl}antiseizure/non-medical-history/${surveyId}`;
    return this.httpClient.get<any>(url);
  }
  

  

  sendSubmittedEmail(email:string, as_survey_id:string){
    const url = `${this.serverUrl}antiseizure/assurveysubmitmail`;
    return this.httpClient.post<any>(url,{'email':email, 'survey_id':as_survey_id} );
  }

  updateSurveyStatus(surveyId: string): Observable<any> {
    const url = `${this.serverUrl}antiseizure/updateSurveyStatus`; // Replace with the correct API endpoint
    const requestBody = { surveyId: surveyId };
    return this.httpClient.post<any>(url, requestBody);
  }
}
