export var medication_list=[
    {
      "active_ingredient": "Acetazolamide ",
      "trade_name": "Diamox"
    },
    {
      "active_ingredient": "ACTH",
      "trade_name": "Actar"
    },
    {
      "active_ingredient": "Brivaracetam ",
      "trade_name": "Briviact"
    },
    {
      "active_ingredient": "Cannabidiol",
      "trade_name": "Epidiolex"
    },
    {
      "active_ingredient": "Carbamazepine",
      "trade_name": "Tegretol"
    },
    {
      "active_ingredient": "Cenobamate",
      "trade_name": "Xcopri"
    },
    {
      "active_ingredient": "Clobazam",
      "trade_name": "CLB, Onfi, Frisium"
    },
    {
      "active_ingredient": "Clonozapam",
      "trade_name": "CLZ, Klonopin"
    },
    {
      "active_ingredient": "Clorazepat",
      "trade_name": "Tranxene"
    },
    {
      "active_ingredient": "Diazepam",
      "trade_name": "Diazem"
    },
    {
      "active_ingredient": "Eslicarbazepine",
      "trade_name": "Aptiom"
    },
    {
      "active_ingredient": "Ethosuximide",
      "trade_name": "ESX Zarontin"
    },
    {
      "active_ingredient": "Felbamate",
      "trade_name": "FBM, Felbato"
    },
    {
      "active_ingredient": "Fenfluramine ",
      "trade_name": "Fintepla"
    },
    {
      "active_ingredient": "Gabapentin",
      "trade_name": "GBP, Neurontin"
    },
    {
      "active_ingredient": "Lacosamide",
      "trade_name": "LCM, Vimpat"
    },
    {
      "active_ingredient": "Lamotrigine",
      "trade_name": "LTG, Lamictal"
    },
    {
      "active_ingredient": "Levetiracetam ",
      "trade_name": "LVT, Keppra"
    },
    {
      "active_ingredient": "Methosuximide",
      "trade_name": "Celontin"
    },
    {
      "active_ingredient": "Oxcarbazepine",
      "trade_name": "OXC, Trileptal"
    },
    {
      "active_ingredient": "Perampanel",
      "trade_name": "Fycompa"
    },
    {
      "active_ingredient": "Phenobarbital ",
      "trade_name": "PHB, Luminal"
    },
    {
      "active_ingredient": "Phenytoin",
      "trade_name": "PHT, Dilantin"
    },
    {
      "active_ingredient": "Prednisolone",
      "trade_name": " "
    },
    {
      "active_ingredient": "Pregabalin ",
      "trade_name": "PGB, Lyrica"
    },
    {
      "active_ingredient": "Primidone ",
      "trade_name": "Mysoline"
    },
    {
      "active_ingredient": "Retigabine/Ezogabine",
      "trade_name": "Trobalt, Potiga"
    },
    {
      "active_ingredient": "Rufinamide",
      "trade_name": "RFM, Banzel"
    },
    {
      "active_ingredient": "Stiripentol",
      "trade_name": "Diacomit"
    },
    {
      "active_ingredient": "Tiagabine",
      "trade_name": "TGB, Gabatril"
    },
    {
      "active_ingredient": "Topiramate ",
      "trade_name": "TPM, Topamax, Trokendi"
    },
    {
      "active_ingredient": "Valproic Acid ",
      "trade_name": "VPA, Depakote, Depakene, Micropakine"
    },
    {
      "active_ingredient": "Vigabatrin ",
      "trade_name": "VGB, Sabril, vigadrone"
    },
    {
      "active_ingredient": "Zonisamide",
      "trade_name": "ZNS, Zonegran"
    }
   ]