import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, FormControl, Validators, FormArray } from '@angular/forms';
import { MatStepper, MatTableDataSource } from '@angular/material';
import { Country, State, City } from 'country-state-city';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { medication_list } from './medications_list';
import { css } from 'jquery';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common'
import { BehaviorSubject, of } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { MonthpickerDateAdapter } from './monthpicker_date_format';
import { Platform } from '@angular/cdk/platform';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NfaService } from 'app/one-pass/nfa.service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FetcherService } from 'app/fetcher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { json } from 'd3';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
export interface courseMed {
  medicine_name: string;
  start_date: string;
  still_using: string;
  end_date: string;
}

@Component({
  selector: 'app-med-survey',
  templateUrl: './med-survey.component.html',
  styleUrls: ['./med-survey.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MonthpickerDateAdapter,
    deps: [MAT_DATE_LOCALE, Platform],
  },]
})
export class MedSurveyComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  @ViewChild(FormGroupDirective, { static: true }) formGroupDirective: FormGroupDirective;
  @ViewChild('formDirective', { static: true }) myFormGroup_directive: NgForm;
  @Output() public monthAndYearChange = new EventEmitter<Date | null>();
  @ViewChild('goUp', { static: true }) contentPage: ElementRef;
  isLinear = true;
  isEditable = true;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  nonMedicalFormGroup: FormGroup;
  addMedsFormGroup: FormGroup;
  allMedications = []
  medicationsNamesList = []
  raceArr = ["Hispanic/Latino", "Black/African American", "White", "Asian", "Two or more races", "Prefer not to answer", "Other"]
  countryArr = []
  stateArr = []
  typeOfSeizuresArr = ["Absence seizures (staring spells, formerly known as petit mal)", "Tonic-clonic or convulsive seizures (presents as body stiffening and rhythmic jerking of arms and/or legs, formerly known as grand mal)", "Atonic seizures (also known as drop attacks)", "Clonic seizures (presents as rhythmic jerking of arms and/or legs)", "Tonic seizures (presents as body stiffening episodes)", "Focal seizures (presents as one-sided stiffening/jerking which may expand to whole body movements, eyes sometimes roll to one side)", "Myoclonic seizures (presents as sudden muscle jerks)", "Epileptic spasm (formerly known as infantile spasm)", "None of the above, I will describe below"]
  typeOfSeizuresArrForWhichMedsWorked = ["Absence seizures (staring spells, formerly known as petit mal)", "Tonic-clonic or convulsive seizures (presents as body stiffening and rhythmic jerking of arms and/or legs, formerly known as grand mal)", "Atonic seizures (also known as drop attacks)", "Clonic seizures (presents as rhythmic jerking of arms and/or legs)", "Tonic seizures (presents as body stiffening episodes)", "Focal seizures (presents as one-sided stiffening/jerking which may expand to whole body movements, eyes sometimes roll to one side)", "Myoclonic seizures (presents as sudden muscle jerks)", "Epileptic spasm (formerly known as infantile spasm)", "None of the above, I will describe below"]
  showGantt = false
  maxDate: any;
  monthNums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  ageNums = Array.from(Array(101).keys())

  ganttBucket = 10
  addMedsCommentVal = ""
  addMedsFormGroupInitialValue: any

  private logoutTimer: number;
  private logoutTimerSubscription: Subscription;

  constructor(public datepipe: DatePipe, private _formBuilder: FormBuilder, public dialog: MatDialog, private dateAdapter: DateAdapter<Date>, private changeDetectorRefs: ChangeDetectorRef, private nfa: NfaService, private fs: FetcherService, private route: ActivatedRoute, private router: Router) {
    this.dateAdapter.setLocale('en-US'); //MM/DD/yyyy
    let today_date = new Date()
    // this.maxDate = this.datepipe.transform(today_date, 'dd/MM/yyyy');
    this.maxDate = new Date()
    this.medicationsNamesList = medication_list
    this.countryArr = Country.getAllCountries()
    // var date_regex = new RegExp('(\d?\d?)\/(\d?|\d[0-9])\/(19|20)\d{2}', '')
    var date_regex = new RegExp('^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$', '')
    this.countryArr = this.countryArr.filter(x => x.name == "United States").concat(this.countryArr)

    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      date_of_birth: ['', Validators.required],
      race: ['', Validators.required],
      other_race: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],

    });

    this.secondFormGroup = this._formBuilder.group({
      first_seizure_age_years: ['', Validators.required],
      first_seizure_age_months: ['', Validators.required],
      type_of_seizure: ['', Validators.required],
      // units: ['', Validators.required],
      comment: [''],
      number_of_medications: ['', Validators.required]
    });

    this.nonMedicalFormGroup = this._formBuilder.group({
      yes_or_no: ['', Validators.required]
    });

    this.addMedsFormGroup = this._formBuilder.group({
      medicine_name: ['', Validators.required],
      start_date: ['', [Validators.required]],
      still_using: ['', Validators.required],
      end_date: ['', [Validators.required]],

      age_years_during_medication: ['', Validators.required],
      age_months_during_medication: ['', Validators.required],
      reason_for_medication: ['', Validators.required],
      other_reason: ['', Validators.required],
      addition_to_previous: ['', Validators.required],
      discontinued_previous: ['', Validators.required],
      discontinued_medicine_name: ['', Validators.required],

      did_it_work: ['', Validators.required],
      worked_for_which_seizures: ['', Validators.required],
      side_effects: ['', Validators.required],
      side_effects_list: ['', Validators.required],
      others_specify: ['', Validators.required],
      side_effect_comments: ['', Validators.required],
      comments: [''],
    })


  }

  // (dateChange)="emitDateChange($event, 'addMedsFormGroup','start_date')"
  emitDateChange(event: MatDatepickerInputEvent<Date | null>, formName, controlName): void {
    this.monthAndYearChange.emit(event.value);
  }

  monthChanged(value: any, widget: any, formName, controlName) {
    let date = value
    this[formName].get(controlName).setValue(value)

    // this[formName].get(controlName).setValue(this.dateFormatSet(value))
    // this.addMedsFormGroup.patchValue( {controlName:this.dateFormatSet(value)})
    // this.monthAndYearChange.emit(value);
    widget.close();
  }

  selectCountry(country) {
    this.stateArr = State.getStatesOfCountry(country.isoCode)
  }

  raceChangeTrigger(race) {
    if (race == "Other") {
      this.firstFormGroup.get('other_race').enable()
    }
    else {
      this.firstFormGroup.get('other_race').disable()
    }
  }

  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }

  tokenLogout() {
    if (this.logoutTimerSubscription) {
      this.logoutTimerSubscription.unsubscribe();
    }
    this.nfa.logout();
  }

  loggedInUserEmail: any;
  initialPatchValues:any;
  formNames = ['firstFormGroup', 'secondFormGroup','addMedsFormGroup']
  ngOnInit() {
    this.logoutTimer = 6000 * 1000;
    this.startLogoutTimer();

    this.nfa.currentEmail.subscribe(email => {
      this.loggedInUserEmail = email;
      this.loggedInUserEmail = localStorage.getItem("survey_user_email")
    });

    // this.addMedsFormGroup.patchValue(this.addMedsFormGroupInitialValue)
    let test_second_med = { "age_during_medication": "medication_number", "units": "medication_number", "names_of_medication": [{ "active_ingredient": "Acetazolamide ", "trade_name": "Diamox" }], "did_it_work": "yes", "percent_of_reduction": ["1-25% reduction"], "how_long_did_it_work": ["Less than 1 week"], "average_duration_of_seizure": ["Few seconds to 1 minute"], "frequency_of_seizure": ["Daily multiple times"], "side_effects": "yes", "side_effects_list": ["Worsening of seizures"], "side_effect_comments": "medication_number", "comments": "medication_number" }

    this.courses = [{
      "course_num": 1,
      "medications": [
        
        {
          "medicine_name": "Actar",
          "start_date": "02/2017",
          "still_using": "No",
          "end_date": "06/2017",
          "age_years_during_medication": 2,
          "age_months_during_medication": 2,
          "reason_for_medication": [
              "Another type of seizure started (If yes, please select the type of seizure type)",
              "Other, I will describe below"
          ],
          "other_reason": "asdfasdf ",
          "addition_to_previous": "no",
          "discontinued_previous": "yes",
          "discontinued_medicine_name": "Diamox",
          "did_it_work": "Unsure",
          "worked_for_which_seizures": [
              "Absence seizures (staring spells, formerly known as petit mal)",
              "Tonic-clonic or convulsive seizures (presents as body stiffening and rhythmic jerking of arms and/or legs, formerly known as grand mal)"
          ],
          "side_effects": "Yes",
          "side_effects_list": [
              "Worsening of seizures",
              "Drowsiness/sedation",
              "Others"
          ],
          "others_specify": "asdfemghkl",
          // "id": 887894
      }
      ]
    }]
    
    this.courses = [{ "course_num": 1,"medications": []}]
    // this.closeRightColumn();
    this.showTables();
    // this.showGantt=false; this.showGantt=true
    this.route.queryParams.subscribe(res=>{
      console.log(res)
    })

    this.route.queryParams.subscribe(params => {
      console.log(params)
      if(Object.keys(params).length > 0){
        this.initialPatchValues = JSON.parse((params['value']))
        this.as_survey_id = this.initialPatchValues[0]['survey_id']
        this.patchFirstForm()
        this.generalFeaturesInit();
        this.patchMedHistory();
        this.nonMedsInit();
        this.isLinear=false;

      } 
    })
  
    // for progress view use this 
    // this.formNames.forEach(formName => {
    //   const form: FormGroup = this[formName];
    //   form.valueChanges.subscribe(() => {
    //     const { totalControls, answeredControls } = this.getFormControlsStats(formName);
    //     console.log(`Form: ${formName}`);
    //     console.log('Total Controls:', totalControls);
    //     console.log('Answered Controls:', answeredControls);
    //   });
    // });
  }

  getFormControlsStats(formName: string): { totalControls: number, answeredControls: number } {
    const form: FormGroup = this[formName];
    const controls = form.controls;
    const totalControls = Object.keys(controls).length;
    let answeredControls = 0;
  
    for (const controlName in controls) {
      if (controls.hasOwnProperty(controlName)) {
        const control = controls[controlName];
        if (control.enabled && control.value !== null && control.value !== '') {
          answeredControls++;
        }
      }
    }
  
    return { totalControls, answeredControls };
  }
  
  //populate second form of AS 
  generalFeaturesInit() {
    this.fs.getGeneralFeaturesBySurveyId(this.as_survey_id).subscribe(res => {
      if(res && res.length > 0){
        let patch_val = <any>res[0];
        if (patch_val.type_of_seizure.includes("||")) {
          patch_val.type_of_seizure = patch_val.type_of_seizure.split("||")
        }
        else {
          patch_val.type_of_seizure = [patch_val.type_of_seizure]
        }
        this.secondFormGroup.patchValue(patch_val)
         this.triggerFirstSeizure(this.secondFormGroup.get('type_of_seizure').value)
      }
     
      
    })
  }

  // updates or creates general features (second form)
  generalFeaturesSubmit() {

    let sub_val: any = {}
    if (this.secondFormGroup.valid) {
      sub_val = { ...this.secondFormGroup.value }
      sub_val.type_of_seizure = sub_val.type_of_seizure.join("||")
      // sub_val["survey_id"] = this.as_survey_id
      this.fs.updateOrCreateGeneralFeatures(this.as_survey_id, sub_val).subscribe(res => {
        console.log(res)
      })
    }
  }

  //populate first form of anti seizure survey has been started already
  as_survey_id = ""
  patchFirstForm() {
    this.route.queryParams.subscribe(params => {
      if(Object.keys(params).length > 0){
      let patchValues = JSON.parse((params['value']))
      console.log(patchValues)
      this.as_survey_id = patchValues[0]['survey_id']
      let country_selected = this.countryArr.filter(x => x.name == this.initialPatchValues[0]['country'])
      this.initialPatchValues[0]['country'] = country_selected[0]
      let selectedCountryStates = State.getStatesOfCountry(this.initialPatchValues[0]['country']['isoCode'])
      this.selectCountry(this.initialPatchValues[0]['country'])
      let selectedState = selectedCountryStates.filter(x => x.name == this.initialPatchValues[0]['state'])
      this.initialPatchValues[0]['state'] = selectedState[0].name
      const utcDate = new Date(this.initialPatchValues[0]['date_of_birth']);
      const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
      this.initialPatchValues[0]['date_of_birth'] = new DatePipe('en-US').transform(localDate, 'yyyy-MM-dd');
      this.firstFormGroup.patchValue(this.initialPatchValues[0])
      console.log(this.initialPatchValues)
      this.raceChangeTrigger(this.firstFormGroup.get('race').value)
      this.firstFormGroup.disable()
    }
    })
  }

  patchMedHistory(){
    this.showGantt=false
    console.log("initial patching of med history to table")
    this.fs.getCombinedMedicalHistory(+this.as_survey_id).subscribe(res=>{
      console.log(res)
      let med_hist_res= <any>res
      if(med_hist_res!=null) {
        this.courses[0]['medications'] = med_hist_res;
        this.showGantt=true;
        this.openRightColumn()
      }
      else {this.courses[0]['medications'] = []}
      this.showTables()
    })
  }
  //creates anti seizure survey first entry 
  createAntiSeizureSurvey() {

    let obj: any = {}
    obj.name = this.firstFormGroup.value.name
    obj.date_of_birth = this.firstFormGroup.value.date_of_birth
    obj.race = this.firstFormGroup.value.race
    obj.state = this.firstFormGroup.value.state
    obj.country = this.firstFormGroup.value.country.name
    obj.other_race = this.firstFormGroup.value.other_race ? this.firstFormGroup.value.other_race : "NA"
    obj.email_id = localStorage.getItem('survey_user_email')

    if (this.firstFormGroup.valid) {
      this.fs.createAntiSeizureSurvey(obj).subscribe(res => {
        if(res){
          this.as_survey_id = res.survey_id
        }
      })
    }
  }

  joinArrayToString(arr: string[]): string {
    return arr.join('||');
  }

  splitStringToArray(str: string): string[] {
    if (str.includes('||')) {
      return str.split('||');
    } else {
      return [str];
    }
  }

  
  private startLogoutTimer() {
    this.logoutTimerSubscription = of(null)
      .pipe(delay(this.logoutTimer))
      .subscribe(() => {
        this.showSessionPrompt();
      });
  }

  private async showSessionPrompt() {
    const shouldExtendSession = confirm('Do you want to extend your session?');

    if (shouldExtendSession) {
      // Request a new JWT from the server
      const response = await this.nfa.refreshJWT().toPromise();

      // Update the JWT in your Angular application
      // ...

      // Reset the logout timer
      this.startLogoutTimer();
    } else {
      // Log out the user if they don't want to extend the session
      this.tokenLogout();
    }
  }

  ngAfterViewInit() {
    this.myStepper.selectedIndex = 0
  }

  courses = []
  presentCourseNum = 0
  showAddCourseButton = false
  addMedModeSwitch = true

  addModeSwitchFlip() {
    this.addMedModeSwitch = !this.addMedModeSwitch
  }

  addCourseButtonState() {

    let temp = { ...this.courses[this.presentCourseNum] }
  }
  addNotesToPresent() {
    this.openDialog(this.presentCourseNum);
  }

  addCourse(num) {
    if (!this.courses[this.presentCourseNum]['notes']) {
      this.openDialog(this.presentCourseNum)
    }
    else {

      if (this.courses[this.presentCourseNum]["medications"].length != 0) {
        this.presentCourseNum += 1
        this.courses.push({ course_num: this.presentCourseNum + 1, medications: [] })
      }

      else {
        // this.addCourseButtonState()
      }
    }
    this.addMedModeSwitch = true
    this.showTables()
  }

  selectCourse(num) {
    this.presentCourseNum = num
  }

  dateFormatSet(e) {
    // let format_date = this.datepipe.transform(e, 'yyyy/MM/dd');
    let format_date = this.datepipe.transform(e, 'MM/yyyy');
    // // this.addMedsFormGroup.controls[controlName].setValue(latest_date)
    // this.addMedsFormGroup.patchValue({controlName : format_date})
    return (format_date)
  }

  setEndDateStatus(stillUsingState) {
    if (stillUsingState == "Yes") { this.addMedsFormGroup.get('end_date').disable() }
    else { this.addMedsFormGroup.get('end_date').enable() }
  }

  leftColWidth = '12';
  rightColWidth = '0';
  listOpen = false;
  closeRightColumn() {
    this.rightColWidth = '0';
    this.leftColWidth = '12'
    this.listOpen = false
  }

  openRightColumn() {
    this.rightColWidth = '4';
    this.leftColWidth = '8';
    this.listOpen = true
  }

  //general features - secondFormGroup
  triggerFirstSeizure(firstSeizureType) {

    if (firstSeizureType.indexOf("None of the above, I will describe below") > -1) {
      this.secondFormGroup.get('comment').enable()
      this.secondFormGroup.get('type_of_seizure').patchValue(["None of the above, I will describe below"])
    }
    else {
      if (firstSeizureType.indexOf("None of the above, I will describe below") > -1) {
        let ind = firstSeizureType.indexOf("None of the above, I will describe below")
        firstSeizureType.splice(ind, 1)
      }
      this.secondFormGroup.get('comment').disable()
    }

    console.log(this.secondFormGroup.get('type_of_seizure').value)
  }

  effectsOfMed = {}
  effectsChildAccumulator(response) {
    this.effectsOfMed[response.seizure_type] = response.form_values
    console.log(this.effectsOfMed)
  }

  sub_form_array_seizures_list=[]
  medEffectOnSeizuresTrigger(seizuresUponWhichThisMedWorked) {
    if(seizuresUponWhichThisMedWorked.indexOf("None of the above, I will describe below")>-1){
      this.addMedsFormGroup.get('worked_for_which_seizures').patchValue(["None of the above, I will describe below"])
      this.sub_form_array_seizures_list=[]
    }
    else{
      this.sub_form_array_seizures_list=this.addMedsFormGroup.get('worked_for_which_seizures').value
    }
    
  }

  additionReasonTrigger(reasonsToAddMed) {
    if (reasonsToAddMed.indexOf("Other, I will describe below") > -1) {
      this.addMedsFormGroup.get("other_reason").enable()
    }
    else {
      this.addMedsFormGroup.get("other_reason").disable()
    }
  }

  medicationWorkedTrigger(workedState) {
    if (workedState == "No") {
      // this.addMedsFormGroup.get('percent_of_reduction').disable()
      // this.addMedsFormGroup.get('how_long_did_it_work').disable()
      // this.addMedsFormGroup.get('average_duration_of_seizure').disable()
      // this.addMedsFormGroup.get('frequency_of_seizure').disable()
      // this.addMedsFormGroup.get('side_effects').disable()
      // this.addMedsFormGroup.get('side_effects_list').disable()
      // this.addMedsFormGroup.get('side_effect_comments').disable()
      this.addMedsFormGroup.get('worked_for_which_seizures').disable()
      this.addMedsFormGroup.get('comments').enable()
      this.addMedsFormGroup.get('worked_for_which_seizures').patchValue([])
      this.sub_form_array_seizures_list=[]
    }

    else if (workedState == "Yes" || workedState == "Unsure") {
      // this.addMedsFormGroup.get('percent_of_reduction').enable()
      // this.addMedsFormGroup.get('how_long_did_it_work').enable()
      // this.addMedsFormGroup.get('average_duration_of_seizure').enable()
      // this.addMedsFormGroup.get('frequency_of_seizure').enable()
      // this.addMedsFormGroup.get('side_effects').enable()
      // this.addMedsFormGroup.get('side_effects_list').enable()
      // this.addMedsFormGroup.get('side_effect_comments').enable()
      this.addMedsFormGroup.get('worked_for_which_seizures').enable()
      this.addMedsFormGroup.get('comments').disable()
    }
  }

  sideEffectTrigger(sideEffectState) {
    if (sideEffectState == "No") {
      this.addMedsFormGroup.get('side_effects_list').disable()
      this.addMedsFormGroup.get('side_effect_comments').disable()
    }
    else if (sideEffectState = "Yes") {
      this.addMedsFormGroup.get('side_effects_list').enable()
      this.addMedsFormGroup.get('side_effect_comments').enable()
    }
  }

  sideEffectsListTrigger(sideEffectsList) {
    if (sideEffectsList.indexOf("Another type of seizure started") >= 0) {
      this.addMedsFormGroup.get('side_effect_comments').enable()
    }
    else {
      this.addMedsFormGroup.get('side_effect_comments').disable()
    }

    if (sideEffectsList.indexOf("Others") >= 0) {
      this.addMedsFormGroup.get('others_specify').enable()
    }
    else {
      this.addMedsFormGroup.get('others_specify').disable()
    }
  }

  medDiscontinueTrigger(discState) {
    if (discState == "yes") {
      this.addMedsFormGroup.get('discontinued_medicine_name').enable()

    }
    else if (discState = "no") {
      this.addMedsFormGroup.get('discontinued_medicine_name').disable()

    }
  }

  patchMode = false
  patchMedIndex = -1
  patched_effects_of_meds_on_seizure:any
  patchMed(med_index) {
    this.patchMedIndex = med_index
    this.patchMode = true
    let selected = { ...this.courses[this.presentCourseNum]["medications"][med_index] }
    selected.medicine_name = this.medicationsNamesList.filter(x => x.trade_name == selected.medicine_name)
    console.log(selected.medicine_name)
    selected.discontinued_medicine_name = this.medicationsNamesList.filter(x => x.trade_name == selected.discontinued_medicine_name)
    selected.medicine_name = selected.medicine_name[0]
    selected.discontinued_medicine_name = selected.discontinued_medicine_name[0]
    this.effectsOfMed = selected.med_worked_upon
    let start_date = selected['start_date'].split("/")
    let end_date = selected['end_date'].split("/")
    start_date.splice(1, 0, "/01/")
    end_date.splice(1, 0, "/01/")
    start_date = start_date.join()
    end_date = end_date.join()
    selected['start_date'] = new Date(start_date)
    selected['end_date'] = new Date(end_date)
    //********************* */
    console.log("selected and patched")
    console.log(this.courses[this.presentCourseNum]["medications"][med_index])
    console.log(selected)
    //********************* */
    this.patched_effects_of_meds_on_seizure = selected['med_worked_upon']
    this.addMedsFormGroup.patchValue(selected)

    this.setEndDateStatus(this.addMedsFormGroup.get('still_using').value)
    this.medDiscontinueTrigger(this.addMedsFormGroup.get('discontinued_previous').value)
    this.medicationWorkedTrigger(this.addMedsFormGroup.get('did_it_work').value)
    this.sideEffectTrigger(this.addMedsFormGroup.get('side_effects').value)
    this.sideEffectsListTrigger(this.addMedsFormGroup.get('side_effects_list').value)
    this.additionReasonTrigger(this.addMedsFormGroup.get('reason_for_medication').value)
    this.medEffectOnSeizuresTrigger(this.addMedsFormGroup.get('worked_for_which_seizures').value)
  }

  editMeds() {
    // med_value.med_worked_upon = this.effectsOfMed
    let med_index = this.patchMedIndex
    let present_row_id = this.courses[this.presentCourseNum]["medications"][med_index]['id']
    let med_value = { ...this.addMedsFormGroup.value }
    med_value.id = present_row_id
    med_value.start_date = this.dateFormatSet(med_value.start_date);
    let currentDate = new Date();
    med_value.end_date = med_value.end_date ? this.dateFormatSet(med_value.end_date) : this.datepipe.transform(currentDate, 'MM/yyyy')
    med_value.medicine_name = med_value.medicine_name.trade_name
    if (med_value.discontinued_medicine_name) {
      med_value.discontinued_medicine_name = med_value.discontinued_medicine_name.trade_name
    }
    med_value.med_worked_upon = this.effectsOfMed
    //********************* */
    console.log(present_row_id)
    console.log(med_value)
    //********************* */
    this.fs.updateOrCreateCombinedMedicalHistory(+this.as_survey_id,med_value).subscribe(res=>{
      console.log(res)
      alert("updated")
      med_value.id = res.id
      this.courses[this.presentCourseNum]["medications"][med_index] = med_value
      this.patchMedHistory()
      // this.showTables();
      this.sub_form_array_seizures_list=[]
      this.patched_effects_of_meds_on_seizure=undefined
      this.patchMode = false
      this.addMedsFormGroup.reset(this.addMedsFormGroupInitialValue)
    })
  }

  removeMedicine(med_index) {
    let medication_id = this.courses[this.presentCourseNum]["medications"][med_index]['id'];
    this.fs.deleteMedicalHistory(medication_id).subscribe(
      (response) => {
        // Success scenario
        if (response.msg === "Medical history deleted successfully") {
          alert("Medication deleted");
          this.showTables();
          // Perform any additional actions or updates after successful deletion
        } else {
          console.log("Error deleting medical history:", response.msg);
          // Handle the error, display an error message, or perform any necessary actions
        }
      },
      (error) => {
        // Error scenario
        console.log("Error deleting medical history:", error);
        alert("Medication deleted");
        this.patchMedHistory()
          // this.showTables();
        // Handle the error, display an error message, or perform any necessary actions
      }
    );
  }
  

  percentOfReductionArr = ["1-25% reduction", "26-50% reduction", "51-75% reduction", "76-99% reduction", "100% response, with no seizure"]
  howLongArr = ["1 to 6 months", "6 months to 1 year", "More than 1 year"]
  averageDurationArr = ["Seconds only", "Few seconds to 1 min", "1 to 3 minutes", "3 to 5 minutes", "5 to 30 minutes", "30 minutes to longer"]
  seizureFreqArr = ["Daily multiple times", "Daily to weekly", "Weekly to monthly", "Monthly or less frequent"]
  sideEffectArr = ["Worsening of seizures", "Regression in developmental skills", "Drowsiness/sedation", "Rash", "Balance problems", "Liver enzyme elevation", "Kidney stone", "Urinary retention", "Another type of seizure started", "Others"]
  reasonsForSecondMedication = ["First seizure onset", "Worsening of the previous seizure type", "Another type of seizure started (If yes, please select the type of seizure type)", "Inadequate response to previous seizure medication", "Intolerable side effects of the previous seizure medication", "Other, I will describe below"]

  submitAddMeds() {
    let med_value = { ...this.addMedsFormGroup.value }
    console.log(med_value)
    med_value.med_worked_upon = this.effectsOfMed
    med_value.start_date = this.dateFormatSet(med_value.start_date)
    let currentDate = new Date();
    med_value.end_date = med_value.end_date ? this.dateFormatSet(med_value.end_date) : this.datepipe.transform(currentDate, 'MM/yyyy')
    med_value.medicine_name = med_value.medicine_name.trade_name
    if (med_value.discontinued_medicine_name) {
      med_value.discontinued_medicine_name = med_value.discontinued_medicine_name.trade_name
    }

    if (this.addMedsFormGroup.valid) {
      // this always points to course_num = 1 bcz course are not being added
      let index = this.courses.findIndex(x => x.course_num == this.presentCourseNum + 1) 
      if (index == -1) {
        this.courses.push({ course_num: this.presentCourseNum + 1, medications: [med_value] })
      }
      else { // this is the only case that keeps happening
        this.fs.updateOrCreateCombinedMedicalHistory(+this.as_survey_id,med_value).subscribe(res=>{
          
        console.log(res)
        med_value.id = res.id
        console.log(med_value)
        this.courses[index]["course_num"] = this.presentCourseNum + 1
        this.courses[index]["medications"].push(med_value)

        this.addMedModeSwitch = false
        this.addMedsCommentVal = ""
        this.sub_form_array_seizures_list=[]
        this.patched_effects_of_meds_on_seizure=undefined
        this.addMedsFormGroup.markAsUntouched()
        this.addMedsFormGroup.markAsPristine()
        this.myFormGroup_directive.resetForm()
        this.patchMedHistory()
        // this.showTables();
        alert("updated")
        })

        
      }
      
      
      
      // this.showUp()
      // this.addMedsFormGroup.reset(this.addMedsFormGroupInitialValue)
      // this.addMedsFormGroup.reset({},{emitEvent:false})

    }
    if (this.courses[0]["medications"].length == 1) {
      this.openRightColumn()
    }

  }

  displayedColumns: string[] = ['extra_col', 'medicine_name', 'start_date', 'still_using', 'end_date'];
  showTableValues: any = []
  showTables() {
    this.courses = [...this.courses]
    this.courses.map(eachCourse => {
      this.showTableValues[eachCourse.course_num - 1] = new MatTableDataSource(eachCourse.medications)
    })
    // this.addCourseButtonState();
  }

  questions_map = {
    "age_during_medication": "Age of the MDS individual started on an anti-seizure medication",
    "units": "In Years / Months",
    "did_it_work": "Did the first medication work?",
    "percent_of_reduction": "If worked, what percent of seizure reduction occurred? ",
    "how_long_did_it_work": "How long did this medication work ?",
    "average_duration_of_seizure": "What is the average duration of seizure while on the first medication?",
    "frequency_of_seizure": "What is the frequency of seizures while on the first medication?",
    "side_effects": "Was there any side effect from the first medication ?",
    "side_effects_list": "List of all side effects",
    "side_effect_comments": "  Please explain if there are any other side effects",
    "comments": "If you have further notes to mention about the first seizure medication, please explain "
  }

  openDialog(number): void {
    const dialogRef = this.dialog.open(MedicationDialog, {
      width: '90vw',
      data: { medication_number: number },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.courses[result.course_num]["notes"] = result.formRes
      this.showTables();
    });
  }

  nonMedicalsList = []
  openNMDialog(name): void {
    const nmdialogRef = this.dialog.open(NonMedicalDialog, {
      width: '90vw',
      data: { medication_name: name, present_list:this.nonMedicalsList },
    });

    nmdialogRef.afterClosed().subscribe(result => {
      if (result) {
        let db_obj:any = this.getReadyForDB(result)
        console.log(db_obj)
        this.fs.createOrUpdateNonMedForSurveyId(this.as_survey_id,db_obj).subscribe(non_med_res=>{
          console.log(non_med_res)
          db_obj.id = non_med_res.id
          this.nonMedsInit()
          // this.nonMedicalsList.push(this.getReadyForDB(result));
          // this.showNonMedTable()
        })
        // console.log(this.getReadyForDB(result))
        // this.nonMedicalsList.push(this.getReadyForDB(result));
        // this.showNonMedTable()
      }
      else{
        alert("Not added to list")
      }
    });
  }

  openNMDialogPatch(nm_obj, mi): void {
    let patch_obj = { ...nm_obj }
    const nmdialogRef = this.dialog.open(NonMedicalDialog, {
      width: '90vw',
      // data: { medication_name: nm_obj.nn_name, patch: true, non_med_obj: this.getReadyForPatch(patch_obj,medication_list), index: mi,present_list:this.nonMedicalsList  },
      data: { medication_name: nm_obj.nn_name, patch: true, non_med_obj: patch_obj, index: mi,present_list:this.nonMedicalsList  },

    });

    nmdialogRef.afterClosed().subscribe(result => {
      if (result) {
        let db_obj:any = this.getReadyForDB(result)
        console.log(db_obj)
        this.fs.createOrUpdateNonMedForSurveyId(this.as_survey_id,db_obj).subscribe(non_med_res=>{
          console.log(non_med_res)
          db_obj.id = non_med_res.id
          this.nonMedsInit()
          // this.nonMedicalsList.splice(mi, 1, this.getReadyForDB(result));
          // this.showNonMedTable()
        })
        // this.nonMedicalsList.splice(mi, 1, this.getReadyForDB(result));
        // this.showNonMedTable()
      }
      else{
        alert("No changes were made by user")
      }
    });
  }

  toMySqlDate(dateStr){
    let monthYearArr = dateStr.split('/');
    let mysqlFormatDate = `${monthYearArr[1]}-${monthYearArr[0]}-01`;
    return (mysqlFormatDate);
  }

  mySqlDateToFormattedDate(mysqlDate){
    let mysqlDateArr = mysqlDate.split('-');
    let formattedForTableDate = `${mysqlDateArr[1]}/${mysqlDateArr[0]}`;
    return (formattedForTableDate)
  }

  getReadyForDB(data: any): any {
    console.log(data)
    const formattedData = { ...data };
  
    // Convert side_effects_list to a string joined by "||"
    if(data.side_effects_list)
      {
        formattedData.side_effects_list = data.side_effects_list.join("||");
      }
    else{
      formattedData.side_effects_list = null
    }
  
    // Convert names_of_medication to a string joined by trade_name
    if(data.names_of_medication){
    formattedData.names_of_medication = data.names_of_medication
      .map((med) => med.trade_name)
      .join("||");}
    else{
      formattedData.names_of_medication = null
    }
  
    formattedData.start_date = this.toMySqlDate(data.start_date)

    if(data.end_date != null) 
    {
      formattedData.end_date = this.toMySqlDate(data.end_date)
    }
    else
    {
      let currentDate = new Date();
      formattedData.end_date = this.toMySqlDate(this.datepipe.transform(currentDate, 'MM/yyyy'))
    }
    return formattedData;
  }

  getReadyForPatch(formattedData: any, medicationArr: any[]): any {
    const reconstructedData = { ...formattedData };
    console.log(reconstructedData)
    // Reconstruct names_of_medication array using medicationArr
    if(formattedData.names_of_medication){
    reconstructedData.names_of_medication = formattedData.names_of_medication
      .split("||")
      .map((tradeName, index) => ({
        active_ingredient: medicationArr[index].active_ingredient,
        trade_name: tradeName,
      }));
    }
    else{
      reconstructedData.names_of_medication = []
    }
    // reconstructedData.names_of_medication = formattedData.names_of_medication.split("||");
    if(formattedData.side_effects_list && formattedData.side_effects_list.includes("||")){
      reconstructedData.side_effects_list = formattedData.side_effects_list.split("||");
    }
    else{
      reconstructedData.side_effects_list = []
    }
      reconstructedData.start_date = this.mySqlDateToFormattedDate(formattedData.start_date)
      reconstructedData.end_date = this.mySqlDateToFormattedDate(formattedData.end_date)
    return reconstructedData;
  }
  
  testNMDEntry = {

    "discontinued_previous": "yes",
    "names_of_medication": "Diamox||Actar",
    "start_date": "05/2023",
    "still_using": "No",
    "end_date": "08/2023",
    "did_it_work": "yes",
    "percent_of_reduction": "1-25% reduction",
    "how_long_did_it_work": "Less than 1 week",
    "average_duration_of_seizure": "Few seconds to 1 minute",
    "average_duration_of_seizure_before_med": "Few seconds to 1 minute",
    "frequency_of_seizure": "Daily multiple times",
    "frequency_of_seizure_before_med": "Daily multiple times",
    "side_effects": "yes",
    "side_effects_list": "Nausea/vomiting||Fatigue",
    "comments": "teaser",
    "nn_name": "Ketogenic diet"
}

  testNMD(){
    this.nonMedicalsList=[this.testNMDEntry]
    console.log(this.nonMedicalsList)
    this.patchNM(0)
  }

  nonMedsInit(){
    this.nonMedicalsList = []
    this.fs.getNonMedsForSurveyId(this.as_survey_id).subscribe(non_med_init_res=>{
      let non_meds:any = non_med_init_res
      if(non_meds.length>0){
        non_meds.map(x=>{
          this.nonMedicalsList.push(this.getReadyForPatch(x, medication_list))
        })
        this.showNonMedTable()
      }
      else{
        this.nonMedicalsList=[]
      }
    })
  }

  shownmdCols = ["extra_col", "nn_name", "start_date", "still_using", "end_date"]
  showNMTableValues: any = []
  showNonMedTable() {
    console.log(this.nonMedicalsList)
    this.showNMTableValues = new MatTableDataSource(this.nonMedicalsList) 
  }

  removeNM(mi) {
    console.log(this.nonMedicalsList[mi])
    let id  = this.nonMedicalsList[mi]['id']
    this.fs.deleteNonMedForId(id).subscribe(res=>{
      console.log(res)
      if(res['msg']=="Non-medical history deleted successfully."){
        this.nonMedsInit()
        alert("Non-Medical Intervention deleted")
      }
      else{
        alert("Something went wrong");
        this.nonMedsInit();
      }
      
    })
    // this.fs.deleteNonMedForSurveyId( )
    // this.nonMedicalsList.slice(mi, 1);
    // this.showNonMedTable()
  }

  patchNM(mi) {
    console.log(mi)
    console.log(this.nonMedicalsList[mi])
    this.openNMDialogPatch(this.nonMedicalsList[mi], mi);
    this.showNonMedTable();
  }

  submitSuccess=false
  confirmSubmissionEmail(){
    this.fs.sendSubmittedEmail(this.loggedInUserEmail, this.as_survey_id).subscribe(res=>{
      console.log(res)
      if(res && res["submitted"]=="yes"){
        console.log('Survey submitted for: '+this.firstFormGroup.get('name').value+" Survey ID: "+ this.as_survey_id);
        this.submitSuccess=true
      }
      else{
        console.log("Some error occurred")
      }
    })
  }

}

@Component({
  selector: 'medication-dialog',
  templateUrl: 'medication-dialog.html',
})
export class MedicationDialog {

  medicationForm: FormGroup;
  secondMedicationForm: FormGroup;
  medicationNum = 0
  constructor(private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MedicationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {


    this.medicationArr = medication_list

    this.medicationForm = this._formBuilder.group({
      age_during_medication: ['', Validators.required],
      units: ['', Validators.required],
      // names_of_medication: ['', Validators.required],
      did_it_work: ['', Validators.required],
      percent_of_reduction: ['', Validators.required],
      how_long_did_it_work: ['', Validators.required],
      average_duration_of_seizure: ['', Validators.required],
      frequency_of_seizure: ['', Validators.required],
      side_effects: ['', Validators.required],
      side_effects_list: ['', Validators.required],
      side_effect_comments: ['', Validators.required],
      comments: ['', Validators.required],
    })

    this.secondMedicationForm = this._formBuilder.group({
      age_during_medication: ['', Validators.required],
      units: ['', Validators.required],
      names_of_medication: ['', Validators.required],
      reason_for_medication: ['', Validators.required],
      addition_to_previous: ['', Validators.required],
      discontiued_previous: ['', Validators.required],
      did_it_work: ['', Validators.required],
      percent_of_reduction: ['', Validators.required],
      how_long_did_it_work: ['', Validators.required],
      average_duration_of_seizure: ['', Validators.required],
      frequency_of_seizure: ['', Validators.required],
      side_effects: ['', Validators.required],
      side_effects_list: ['', Validators.required],
      side_effect_comments: ['', Validators.required],
      comments: ['', Validators.required],
    })
  }

  medicationArr = []
  percentOfReductionArr = ["1-25% reduction", "26-50% reduction", "51-75% reduction", "76-99% reduction", "100% response, with no seizure"]
  howLongArr = ["Less than 1 week", "1-4 weeks", "1 to 6 months", "6 months to 1 year", "More than 1 year"]
  averageDurationArr = ["Few seconds to 1 minute", "1 to 5 minutes", "5 to 30 minutes", "30 minutes or longer"]
  seizureFreqArr = ["Daily multiple times", "Daily to weekly", "Weekly to monthly", "Monthly or less frequent"]
  sideEffectArr = ["Worsening of seizures", "Regression in developmental skills", "Drowsiness/sedation", "Rash", "Balance problems", "Liver enzyme elevation", "Kidney stone", "Urinary retention"]

  reasonsForSecondMedication = ["Worsening of the first seizure type", "Second type of seizure started (If yes, please select the type of seizure type)", "Inadequate response to first seizure medication", "Intolerable side effects of the first seizure medication"]

  addMedicationDetails() {
    console.log(this.data)
    if (this.medicationForm.valid) { this.data.course_num = this.medicationNum; this.data.formRes = this.medicationForm.value; this.dialogRef.close(this.data); }

  }

  addMedication() {
    console.log(this.data)
    if (this.medicationNum == 1) { if (this.medicationForm.valid) { this.data.formRes = this.medicationForm.value; this.dialogRef.close(this.data); } }
    else if (this.medicationNum > 1) { if (this.secondMedicationForm.valid) { this.data.formRes = this.secondMedicationForm.value; this.dialogRef.close(this.data); } }

  }
  ngOnInit() {
    this.medicationNum = this.data.medication_number
  }
  onNoClick(): void {
    this.dialogRef.close();
  }


}

@Component({
  selector: 'non-medical-dialog',
  templateUrl: 'non-medical-dialog.html',
  providers: [{
    provide: DateAdapter,
    useClass: MonthpickerDateAdapter,
    deps: [MAT_DATE_LOCALE, Platform],
  },]
})

export class NonMedicalDialog {
  nonMedicationForm: FormGroup;
  @ViewChild('nonMedForm', { static: true }) nonMedForm: FormGroupDirective;
  constructor(public datepipe: DatePipe, private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<MedicationDialog>, @Inject(MAT_DIALOG_DATA) public data: any,private cd: ChangeDetectorRef) {
    this.nonMedicationForm = this._formBuilder.group({
      discontinued_previous: ['', Validators.required],
      names_of_medication: ['', Validators.required],
      start_date: ['', Validators.required],
      still_using: ['', Validators.required],
      end_date: [''],
      did_it_work: ['', Validators.required],
      percent_of_reduction: ['', Validators.required],
      how_long_did_it_work: ['', Validators.required],
      average_duration_of_seizure: ['', Validators.required],
      average_duration_of_seizure_before_med: ['', Validators.required],
      frequency_of_seizure: ['', Validators.required],
      frequency_of_seizure_before_med: ['', Validators.required],
      side_effects: ['', Validators.required],
      side_effects_list: ['', Validators.required],
      side_effect_comments: ['', Validators.required],
      comments: [''],
    })
  }

  percentOfReductionArr = ["1-25% reduction", "26-50% reduction", "51-75% reduction", "76-99% reduction", "100% response, with no seizure", "Unsure"]
  howLongArr = ["Less than 1 week", "1-4 weeks", "1 to 6 months", "6 months to 1 year", "More than 1 year", "Unsure"]
  averageDurationArr = ["Few seconds to 1 minute", "1 to 5 minutes", "5 to 30 minutes", "30 minutes or longer", "Unsure"]
  seizureFreqArr = ["Daily multiple times", "Daily to weekly", "Weekly to monthly", "Monthly or less frequent", "Unsure"]
  sideEffectArr = ["Worsening of seizures", "Regression in developmental skills", "Drowsiness/sedation", "Rash", "Balance problems", "Liver enzyme elevation", "Kidney stone", "Urinary retention", "None of the above", "Others", "Unsure"]

  reasonsForSecondMedication = ["Worsening of the first seizure type", "Second type of seizure started (If yes, please select the type of seizure type)", "Inadequate response to first seizure medication", "Intolerable side effects of the first seizure medication"]

  medicationArr = []
  allNonMedicals = []

  testArrEntry = {
    "discontinued_previous": "yes",
    "names_of_medication": "Diamox||Actar",
    "start_date": "05/2023",
    "still_using": "No",
    "end_date": "08/2023",
    "did_it_work": "yes",
    "percent_of_reduction": "1-25% reduction",
    "how_long_did_it_work": "Less than 1 week",
    "average_duration_of_seizure": "Few seconds to 1 minute",
    "average_duration_of_seizure_before_med": "Few seconds to 1 minute",
    "frequency_of_seizure": "Daily multiple times",
    "frequency_of_seizure_before_med": "Daily multiple times",
    "side_effects": "yes",
    "side_effects_list": "Nausea/vomiting||Fatigue",
    "comments": "teaser",
    "nn_name": "Ketogenic diet"
}
  maxDate:any;
  ngOnInit() {
    this.maxDate = new Date()
    this.medicationArr = medication_list
    console.log(this.data)
    if (this.data.medication_name == "Ketogenic diet") { this.sideEffectArr = ["Nausea/vomiting", "Headache", "Fatigue", "Dizziness", "Insomnia", "Constipation", "Others", "None of the above"] }
    if (this.data.medication_name == "Vagal Nerve Stimulator") { this.sideEffectArr = ["Worsening of seizures", "Voice changes", "Hoarseness", "Throat pain", "Cough", "Headaches", "Shortness of breath", "Difficulty swallowing", "Tingling or prickling of the skin", "Infection", "Others", "None of the above"] }
    if (this.data.medication_name == "Deep Brain Stimulation" || this.data.medication_name == "Responsive Neurostimulation") { this.sideEffectArr = ["Worsening of seizures", "Infection", "Headache", "Stroke", "Bleeding", "Pain", "Others", "None of the above"] }
    if (this.data.medication_name == "Hemispherectomy" || this.data.medication_name == "Corpus Callosotomy" || this.data.medication_name == "Laser Ablation") { this.sideEffectArr = ["Worsening of seizures", "Infection", "Loss of coordination or balance problems", "Stroke", "Swelling in the brain", "Others", "None of the above"] }
    // if (this.data.medication_name == "Test_nm" ) {  this.sideEffectArr = ["Worsening of seizures", "Infection", "Loss of coordination or balance problems", "Stroke", "Swelling in the brain", "Others", "None of the above"];this.nonMedicationForm.patchValue(this.getReadyForPatch(this.testArrEntry,medication_list)); this.data.patch=true }

    // if (this.data.patch) {

    //   let obj_vals = { ...this.data }
    //   // obj_vals.non_med_obj['start_date']= new  Date (obj_vals.non_med_obj['start_date'])
    //   let start_date = obj_vals.non_med_obj['start_date'].split("/")
    //   start_date.splice(1, 0, "/01/")
    //   start_date = start_date.join()
    //   obj_vals.non_med_obj['start_date'] = new Date(start_date)

    //   if (obj_vals.non_med_obj['end_date'] == "") { obj_vals.non_med_obj['end_date'] = "" }
    //   else {
    //     let end_date = obj_vals.non_med_obj['end_date'].split("/")
    //     end_date.splice(1, 0, "/01/")
    //     end_date = end_date.join()
    //     // obj_vals.non_med_obj['end_date']= new  Date (obj_vals.non_med_obj['end_date'])
    //     obj_vals.non_med_obj['end_date'] = new Date(end_date)
    //   }

    //   // Map array of objects to an array of values
    // let selectedMedicationArr = obj_vals.non_med_obj.names_of_medication.map((medication) => {
    //   return medication.trade_name + " - " + medication.active_ingredient;
    // });

    // this.nonMedicationForm.patchValue(obj_vals.non_med_obj);
    // this.nonMedicationForm.patchValue({ 'names_of_medication': selectedMedicationArr });
    // this.nonMedicationForm.get('names_of_medication').patchValue(selectedMedicationArr) ;

    //   console.log(this.nonMedicationForm.value)
    //   this.setEndDateStatus(this.nonMedicationForm.get('still_using').value)
    //   this.triggerMedWorked(this.nonMedicationForm.get('did_it_work').value);
    //   this.triggerSideEffect(this.nonMedicationForm.get('side_effects').value);
    //   this.triggerSideEffectList(this.nonMedicationForm.get('side_effects_list').value);
    //   this.medDiscontinueTrigger(this.nonMedicationForm.get('discontinued_previous').value);
    //   this.nonMedicationForm.patchValue({ 'names_of_medication': selectedMedicationArr });
      
    // }
  }

  ngAfterViewInit(){
    
    if (this.data.patch) {

      let obj_vals = { ...this.data }
      // obj_vals.non_med_obj['start_date']= new  Date (obj_vals.non_med_obj['start_date'])
      let start_date = obj_vals.non_med_obj['start_date'].split("/")
      start_date.splice(1, 0, "/01/")
      start_date = start_date.join()
      obj_vals.non_med_obj['start_date'] = new Date(start_date)

      if (obj_vals.non_med_obj['end_date'] == "") { obj_vals.non_med_obj['end_date'] = "" }
      else {
        let end_date = obj_vals.non_med_obj['end_date'].split("/")
        end_date.splice(1, 0, "/01/")
        end_date = end_date.join()
        // obj_vals.non_med_obj['end_date']= new  Date (obj_vals.non_med_obj['end_date'])
        obj_vals.non_med_obj['end_date'] = new Date(end_date)
      }

    
    console.log(obj_vals.non_med_obj)
    this.nonMedicationForm.patchValue(obj_vals.non_med_obj);
    // this.nonMedicationForm.get('names_of_medication').valueChanges.subscribe(value => {
    //   console.log(value);
    // });

      console.log(this.nonMedicationForm.value)
      this.setEndDateStatus(this.nonMedicationForm.get('still_using').value)
      this.triggerMedWorked(this.nonMedicationForm.get('did_it_work').value);
      this.triggerSideEffect(this.nonMedicationForm.get('side_effects').value);
      this.triggerSideEffectList(this.nonMedicationForm.get('side_effects_list').value);

      this.nonMedicationForm.patchValue(obj_vals.non_med_obj);
      this.nonMedicationForm.get('names_of_medication').valueChanges.subscribe(value => {
        console.log(value);
      });

      this.medDiscontinueTrigger(this.nonMedicationForm.get('discontinued_previous').value);

      this.cd.detectChanges()
    }
  }
  getReadyForPatch(formattedData: any, medicationArr: any[]): any {
    const reconstructedData = { ...formattedData };
  
    // Reconstruct names_of_medication array using medicationArr
    reconstructedData.names_of_medication = formattedData.names_of_medication
      .split("||")
      .map((tradeName, index) => ({
        active_ingredient: medicationArr[index].active_ingredient,
        trade_name: tradeName,
      }));
  
    return reconstructedData;
  }

  monthChanged(value: any, widget: any, formName, controlName) {
    this[formName].get(controlName).setValue(value)
    widget.close();
    if(controlName=="end_date" && this.data.present_list.length>0)
    {this.checkOverlappingDates(this.data.present_list,this.nonMedForm.value)}
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  dateFormatSet(e) {
    let format_date = this.datepipe.transform(e, 'MM/yyyy');
    return (format_date)
  }

  medDiscontinueTrigger(discontinued) {
    console.log(discontinued)
    if (discontinued == "no") {
      this.nonMedicationForm.get('names_of_medication').disable()
    }
    else {
      this.nonMedicationForm.get('names_of_medication').enable()
    }
  }

  setEndDateStatus(stillUsingState) {
    if (stillUsingState == "Yes") { this.nonMedicationForm.get('end_date').disable(); this.nonMedicationForm.get('end_date').patchValue({}); }
    else { this.nonMedicationForm.get('end_date').enable() }
  }

  triggerMedWorked(worked) {
    if (worked == "no") {
      this.nonMedicationForm.get('percent_of_reduction').disable()
      this.nonMedicationForm.get('how_long_did_it_work').disable()
      this.nonMedicationForm.get('average_duration_of_seizure').disable()
    }
    else {
      this.nonMedicationForm.get('percent_of_reduction').enable()
      this.nonMedicationForm.get('how_long_did_it_work').enable()
      this.nonMedicationForm.get('average_duration_of_seizure').enable()
    }
  }

  triggerSideEffect(exists) {
    if (exists == "no") {
      this.nonMedicationForm.get('side_effects_list').disable()
      this.nonMedicationForm.get('side_effect_comments').disable()
    }
    else {
      this.nonMedicationForm.get('side_effects_list').enable()
      this.nonMedicationForm.get('side_effect_comments').enable()
    }
  }

  triggerSideEffectList(option) {
    if (option.indexOf("None of the above") >= 0) {
      this.nonMedicationForm.get('side_effect_comments').enable()
      this.nonMedicationForm.get('side_effects_list').patchValue(["None of the above"])
    }
    else {
      this.nonMedicationForm.get('side_effect_comments').disable()

    }
  }

  addedNonMedical() {
    
    this.nonMedicationForm.markAllAsTouched()
    if (this.nonMedicationForm.valid) {
      console.log(this.nonMedicationForm.value)
      let response = this.nonMedicationForm.value
      if(this.data.patch){
        response.id = this.data.non_med_obj.id
      }
      response.nn_name = this.data.medication_name
      response.start_date = this.dateFormatSet(response.start_date)
      if (response.end_date != "") { response.end_date = this.dateFormatSet(response.end_date) }
      else response.end_date = ""
      this.dialogRef.close(response)
    }

    else {
      alert("please fill all mandatory fields")
    }
  }

  checkOverlappingDates(entries: any[], entry: any): boolean {
    console.log("overlap check")
    let newEntry = {...entry}
    console.log(newEntry)
    newEntry.nn_name = this.data.medication_name
    newEntry.start_date= this.dateFormatSet(newEntry.start_date)
    if (newEntry.end_date != "") { newEntry.end_date = this.dateFormatSet(newEntry.end_date) }
    else newEntry.end_date = ""
    const overlappingEntry = entries.find(entry => entry.nn_name === newEntry.nn_name && this.isDateRangeOverlapping(entry.start_date, entry.end_date, newEntry.start_date, newEntry.end_date));
    if (overlappingEntry) {
      alert('Overlapping time period found for intervention '+this.data.medication_name+'. Please try a different intervention or enter a non overlapping date range');
      return true;
    }
    return false;
  }

  isDateRangeOverlapping(start1: string, end1: string, start2: string, end2: string): boolean {
    const startDate1 = new Date(this.formatDate(start1));
    const endDate1 = end1 ? new Date(this.formatDate(end1)) : new Date();
    const startDate2 = new Date(this.formatDate(start2));
    const endDate2 = end2 ? new Date(this.formatDate(end2)) : new Date();
  
    return startDate1 <= endDate2 && startDate2 <= endDate1;
  }
  
  formatDate(dateString: string): string {
    const parts = dateString.split('/');
    const year = parts[1];
    const month = parts[0];
    const day = '01';
    return `${year}-${month}-${day}`;
  }

}

