import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'med-gantt',
  templateUrl: './med-gantt.component.html',
  styleUrls: ['./med-gantt.component.scss']
})
export class MedGanttComponent implements OnInit {

  name = 'Cognizant ';
  @Input() medications: any;
  @Input() bucket_size;
  caclulated_bucket_size=10
  @ViewChild('chart',{static:true}) private chartContainer: ElementRef;
  svg: any;
  categories: any;
  dateFormat: any;
  timeScale: any;
  catsUnfiltered: any;
  taskArray: any;
  w:any;
  h:any;
  element:any;
  dateMin:any;
  dateMax:any;

  constructor(private cdRef: ChangeDetectorRef, private datePipe: DatePipe) {

  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes["bucket_size"] && !changes["bucket_size"].isFirstChange()){
      d3.selectAll('svg').remove();
      this.createChart();
    }

    if(changes["medications"] && !changes["medications"].isFirstChange()){
      d3.selectAll('svg').remove();
      this.createChart();
    }
    else{
      console.log("this is first")
    }
    // this.cdRef.markForCheck();
}

  ngOnInit() {

    console.log(this.medications)
    this.createChart();
    this.cdRef.markForCheck();
  }

  ngAfterViewInit(){
    // console.log('ngOnInit');
    // this.createChart();
  }
  medArray:any =[]

  getDataReady(){
    this.medArray=[
      {
        "medicine_name": "Diamox",
        "start_date": "27/12/2022",
        "still_using": "No",
        "end_date": "29/12/2022"
    },
    {
      "medicine_name": "Acetox",
      "start_date": "30/12/2022",
      "still_using": "Yes",
      "end_date": "5/1/2023"
  }]
  console.log(this.medications)
  this.medArray = this.medications[0]['medications']
  console.log(this.medArray[0]['medications'])
    this.taskArray = []
    this.medArray.map(x=>{
      {
        // let start = x.start_date.split("/")[2]+"-"+x.start_date.split("/")[1]+"-"+x.start_date.split("/")[0]
        let end = ""
        if(x.still_using=="Yes"){
          end = this.datePipe.transform(Date.now(),"yyyy/MM/dd")
        }
        else{
          end = x.end_date
        }
        
        // this.taskArray.push( {task : x.medicine_name, startTime:start, endTime:end, status: 'RED', type:"medications",details:"test"});
        this.taskArray.push( {task : x.medicine_name, startTime:x.start_date, endTime:end, status: 'RED', type:"medications",details:"test"});
      }
    })
    // this.dateFormat = d3.timeParse("%Y/%m/%d");
    this.dateFormat = d3.timeParse("%m/%Y");
    this.taskArray.sort((a,b)=> this.dateFormat(b.startTime) - this.dateFormat(a.startTime))
  }


  createChart() {
    
    console.log('createChart');
    this.element = this.chartContainer.nativeElement;
    this.w = 800;
    this.h = 400;


    this.svg = d3.select(this.element)
    // this.svg = d3.select("chart")
      .append("svg")
      .attr("width", this.w)
      .attr("height", this.h)
      .attr("class", "svg");

    // this.taskArray = [
    //   {
    //     task: "conceptualize",
    //     type: "Development",
    //     startTime: "2013-1-28", //year/month/day
    //     endTime: "2013-2-1",
    //     details: "This actually didn't take any conceptualization",
    //     status: 'GREEN'
    //   },

    //   {
    //     task: "sketch",
    //     type: "Development",
    //     startTime: "2013-2-1",
    //     endTime: "2013-2-6",
    //     details: "No sketching either, really",
    //     status: 'GREEN'
    //   },

    //   {
    //     task: "sketch",
    //     type: "Development",
    //     startTime: "2013-2-6",
    //     endTime: "2013-2-9",
    //     details: "No sketching either, really",
    //     status: 'RED'
    //   },

    //   {
    //     task: "color profiles",
    //     type: "Development",
    //     startTime: "2013-2-6",
    //     endTime: "2013-2-9",
    //     status: 'GREEN'
    //   },

    //   {
    //     task: "HTML",
    //     type: "Coding",
    //     startTime: "2013-2-2",
    //     endTime: "2013-2-6",
    //     details: "all three lines of it",
    //     status: 'RED'
    //   },

    //   {
    //     task: "write the JS",
    //     type: "Coding",
    //     startTime: "2013-2-6",
    //     endTime: "2013-2-9",
    //     status: 'GREEN'
    //   },

    //   {
    //     task: "advertise",
    //     type: "Promotion",
    //     startTime: "2013-2-9",
    //     endTime: "2013-2-12",
    //     details: "This counts, right?",
    //     status: 'RED'
    //   },

    //   {
    //     task: "spam links",
    //     type: "Promotion",
    //     startTime: "2013-2-12",
    //     endTime: "2013-2-14",
    //     status: 'GREEN'
    //   },
    //   {
    //     task: "eat",
    //     type: "Celebration",
    //     startTime: "2013-2-8",
    //     endTime: "2013-2-13",
    //     details: "All the things",
    //     status: 'GREEN'
    //   },

    //   {
    //     task: "crying",
    //     type: "Celebration",
    //     startTime: "2013-2-13",
    //     endTime: "2013-2-16",
    //     status: 'RED'
    //   },

    // ];
    
    this.getDataReady();
    this.dateFormat = d3.timeParse("%m/%Y");
    // this.dateFormat = d3.timeParse("%Y/%m/%d");
    // this.dateFormat = d3.timeParse("%d/%m/%Y");
    this.dateMin=d3.min(this.taskArray, (d:any) => { return this.dateFormat(d.startTime); })
    this.dateMax=d3.max(this.taskArray, (d:any) => { return this.dateFormat(d.endTime); })
    this.timeScale = d3.scaleTime()
          .domain([this.dateMin, this.dateMax])
          .range([0, this.w - 150]);

    let days_between=d3.timeDay.count(this.dateMin, this.dateMax)
    let months_between=d3.timeMonth.count(this.dateMin, this.dateMax)
    let weeks_between=d3.timeWeek.count(this.dateMin, this.dateMax)
    let years_between=d3.timeYear.count(this.dateMin, this.dateMax)
    this.caclulated_bucket_size = Math.ceil(weeks_between/10)
    console.log(this.caclulated_bucket_size)

    console.log(days_between, weeks_between, months_between, years_between)

    this.categories = new Array();

    for (var i = 0; i < this.taskArray.length; i++) {
      this.categories.push(this.taskArray[i].type);
    }

    this.catsUnfiltered = this.categories; //for vert labels

    this.categories = this.checkUnique(this.categories);


    this.makeGant(this.taskArray, this.w, this.h);

    var title = this.svg.append("text")
      .text("Medications timeline")
      .attr("x", this.w / 2)
      .attr("y", 25)
      .attr("text-anchor", "middle")
      .attr("font-size", 18)
      .attr("fill", "#009FFC");


  }


  makeGant(tasks, pageWidth, pageHeight) {
    console.log('makeGant');

    var barHeight = 20;
    var gap = barHeight + 4;
    var topPadding = 75;
    var sidePadding = 75;
    let scale= d3.scaleLinear<string>()
    var colorScale = scale
    .domain([0, this.categories.length])
    .range(["#00B9FA", "#F95002"])
    .interpolate(d3.interpolateHcl);
    // var colorScale = d3.scaleLinear()
    //   .domain([0, this.categories.length])
    //   .range(["#00B9FA", "#F95002"])
    //   .interpolate(d3.interpolateHcl);

    this.makeGrid(sidePadding, topPadding, pageWidth, pageHeight);
    this.drawRects(tasks, gap, topPadding, sidePadding, barHeight, colorScale, pageWidth, pageHeight);
    this.vertLabels(gap, topPadding, sidePadding, barHeight, colorScale);

  }


  makeGrid(theSidePad, theTopPad, w, h) {
    console.log('makeGrid');
    var xAxis = d3.axisBottom(this.timeScale)
      .scale(this.timeScale)
      // .ticks(d3.timeDay)
      .ticks(d3.timeWeek.every(this.caclulated_bucket_size))
      .tickSize(-h + theTopPad + 20)
      .tickSizeOuter(0)
      .tickFormat(d3.timeFormat('%b %y'));



    var grid = this.svg.append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(' + theSidePad + ', ' + (h - 50) + ')')
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "middle")
      .attr("fill", "grey")
      .attr("stroke", "none")
      .attr("font-size", 10)
      .attr("dy", "1em");
  }

  drawRects(theArray, theGap, theTopPad, theSidePad, theBarHeight, theColorScale, w, h) {

    console.log('drawRects');
    var bigRects = this.svg.append("g")
      .selectAll("rect")
      .data(theArray)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("y", (d, i) => {
        return i * theGap + theTopPad - 2;
      })
      .attr("width", (d) => {
        return this.w - theSidePad / 2;
      })
      .attr("height", theGap)
      .attr("stroke", "none")
      .attr("fill", (d) => {
        for (var i = 0; i < this.categories.length; i++) {
          if (d.type == this.categories[i]) {
            if(i%2==0){
                return '#BDBDBD';
            }
            //return d3.rgb(theColorScale(i));
          }
        }
      })
      .attr("opacity", 0.2);


    var rectangles = this.svg.append('g')
      .selectAll("rect")
      .data(theArray)
      .enter();


    var innerRects = rectangles.append("rect")
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("x", (d) => {
        return this.timeScale(this.dateFormat(d.startTime)) + theSidePad;
      })
      .attr("y", (d, i) => {
        return i * theGap + theTopPad;
      })
      .attr("width", (d) => {
        return (this.timeScale(this.dateFormat(d.endTime)) - this.timeScale(this.dateFormat(d.startTime)));
      })
      .attr("height", theBarHeight)
      .attr("stroke", "none")
      .attr("fill", (d) => {
        for (var i = 0; i < this.categories.length; i++) {
          if (d.type == this.categories[i]) {
            return d.status//d3.rgb(theColorScale(i));
          }
        }
      });
    
    
    innerRects.on('mouseover', (e, index, array) => {
      
      console.log('innerRects on mouseover');
      // console.log(index);
      var tag = "";
      // console.log(e);
      if (index.details != undefined) {
        tag = "Task: " + index.task + "<br/>" +
          "Type: " + index.type + "<br/>" +
          "Starts: " + index.startTime + "<br/>" +
          "Ends: " + index.endTime + "<br/>" +
          "Details: " + index.details;
      } else {
        tag = "Task: " + index.task + "<br/>" +
          "Type: " + index.type + "<br/>" +
          "Starts: " + index.startTime + "<br/>" +
          "Ends: " + index.endTime;
      }
      var output = document.getElementById("tag");
      //console.log(array[index]);
      var x = this.timeScale(this.dateFormat(e.startTime)) + theSidePad + ((this.timeScale(this.dateFormat(e.endTime)) - this.timeScale(this.dateFormat(e.startTime)))/2) + "px";
      var y = index * theGap + theTopPad + 25 + "px";
      output.innerHTML = tag;
      output.style.top = y;
      output.style.left = x;
      output.style.display = "block";
    }).on('mouseout', () => {
      var output = document.getElementById("tag");
      output.style.display = "none";

    });


    var rectText = rectangles.append("text")
      .text((d) => {
        return d.task;
      })
      .attr("x", (d) => {
        return (this.timeScale(this.dateFormat(d.endTime)) - this.timeScale(this.dateFormat(d.startTime))) / 2 + this.timeScale(this.dateFormat(d.startTime)) + theSidePad; 
      })
      .attr("y", (d, i) => {
        return i * theGap + 14 + theTopPad;
      })
      .attr("font-size", 11)
      .attr("text-anchor", "middle")
      .attr("text-height", theBarHeight)
      .attr("fill", "#fff");


    rectText.on('mouseover', (e,index) => {
      console.log('rectText on mouseover');
      // console.log(this.x.animVal.getItem(this));
      var tag = "";

      if (e.details != undefined) {
        tag = "Task: " + e.task + "<br/>" +
          "Type: " + e.type + "<br/>" +
          "Starts: " + e.startTime + "<br/>" +
          "Ends: " + e.endTime + "<br/>" +
          "Details: " + e.details;
      } else {
        tag=""
        // tag = "Task: " + e.task + "<br/>" +
        //   "Type: " + e.type + "<br/>" +
        //   "Starts: " + e.startTime + "<br/>" +
        //   "Ends: " + e.endTime;
      }
      var output = document.getElementById("tag");

      var x = (this.timeScale(this.dateFormat(e.endTime)) - this.timeScale(this.dateFormat(e.startTime))) / 2 + this.timeScale(this.dateFormat(e.startTime)) + theSidePad + "px";
      var y = index * theGap + 14 + theTopPad + 25 + "px";
      output.innerHTML = tag;
      output.style.top = y;
      output.style.left = x;
      output.style.display = "block";
    }).on('mouseout', () => {
      var output = document.getElementById("tag");
      output.style.display = "none";
    });


  }


  vertLabels(theGap, theTopPad, theSidePad, theBarHeight, theColorScale) {
    console.log('vertLabels');
    var numOccurances = new Array();
    var prevGap = 0;

    for (var i = 0; i < this.categories.length; i++) {
      numOccurances[i] = [this.categories[i], this.getCount(this.categories[i], this.catsUnfiltered)];
    }
    //console.log(numOccurances);
    var axisText = this.svg.append("g") //without doing this, impossible to put grid lines behind text
      .selectAll("text")
      .data(numOccurances)
      .enter()
      .append("text")
      .text((d) => {
        return d[0];
      })
      .attr("x", 10)
      .attr("y", (d, i) => {
        if (i > 0) {
          for (var j = 0; j < i; j++) {
            prevGap += numOccurances[i - 1][1];
            // console.log(prevGap);
            return d[1] * theGap / 2 + prevGap * theGap + theTopPad;
          }
        } else {
          return d[1] * theGap / 2 + theTopPad;
        }
      })
      .attr("font-size", 11)
      .attr("text-anchor", "start")
      .attr("text-height", 14)
      .attr("fill", (d) => {
        for (var i = 0; i < this.categories.length; i++) {
          if (d[0] == this.categories[i]) {
            //  console.log("true!");
            return '#000';//d3.rgb(theColorScale(i)).darker();
          }
        }
      });
  }

  checkUnique(arr) {
    console.log('checkUnique');
    var hash = {}, result = [];
    for (var i = 0, l = arr.length; i < l; ++i) {
      if (!hash.hasOwnProperty(arr[i])) { //it works with objects! in FF, at least
        hash[arr[i]] = true;
        result.push(arr[i]);
      }
    }
    return result;
  }

  getCounts(arr) {
    console.log('getCounts');
    var i = arr.length, // var to loop over
      obj = {}; // obj to store results
    while (i) obj[arr[--i]] = (obj[arr[i]] || 0) + 1; // count occurrences
    return obj;
  }

  getCount(word, arr) {
    console.log('getCount');
    return this.getCounts(arr)[word] || 0;
  }
}
