import { Component, Inject, OnInit } from '@angular/core';
import { FetcherService } from 'app/fetcher.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfaService } from './nfa.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-one-pass',
  templateUrl: './one-pass.component.html',
  styleUrls: ['./one-pass.component.scss']
})
export class OnePassComponent implements OnInit {
  emailForm: FormGroup;
  codeForm: FormGroup;
  emailSubmitted = false;
  codeSubmitted = false;
  emailSent = false;
  constructor(private fetchService:FetcherService, private _snackBar: MatSnackBar, private formBuilder: FormBuilder, private route: ActivatedRoute, private router:Router, private nfa:NfaService, private dialog: MatDialog) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        console.log(id)
        // Call a service to authenticate the user with the ID
        // Navigate to the med-survey route
        this.nfa.authenticatewithjwt(id).subscribe(res=>{
          console.log(res)
          let resp= <any>res
          if(resp.body.err){
              console.log(resp.body.err)
              alert("The link has expired, please enter the email you have used here to get a new link");
              this.router.navigate(["/one-pass"]);
          }
          this.nfa.setEmail(resp.body.mail); 
          this.router.navigate(["/select-survey"])
        },
        error => {
          console.error(error);
          // Handle the error here
        })
        
      } else {
        // Show the email form
      }
    });

    // this.sendGmail()
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.codeForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }

  get emailFormControl() {
    return this.emailForm.controls;
  }

  get codeFormControl() {
    return this.codeForm.controls;
  }

  sendingEmailProgress = false
  onSubmitEmail() {
    this.sendingEmailProgress = true
    // this.emailSubmitted = true;
    console.log(this.emailFormControl)
    if (this.emailForm.invalid) {
      console.log(this.emailForm.errors)
      return;
    }

    this.nfa.sendTokenEmail(this.emailForm.value.email).subscribe(res=>{
      console.log(res)
      let email_res = <any>res
      if(email_res.statusText=="OK")
        {
          this.openSnackBar("Survey link has been sent to your e-mail. Please check the junk/spam folder if you couldn't find it in your inbox.", "Okay" )
          this.emailForm.reset();
          this.sendingEmailProgress = false
        }
        else{
          alert("Something went wrong")
          this.sendingEmailProgress = false
        }
    })
    // Send email with code to the entered email address
    // Set emailSent flag to true to show the code input field
    // this.emailSent = true;
  }
  onSubmitCode() {
    this.codeSubmitted = true;

    if (this.codeForm.invalid) {
      return;
    }

    // Verify the entered code
    // Call a function to do something with the verified code
  }

  createRandomNumber(){
    return Math.random()*10000
  }
  medSurveyUrl="https://mds.nrihub.org/#/med-survey"
  sendGmail(){
    let htmlMailStr = '<html><head><title></title><style>{box-sizing: border-box;}p,a{font-family: candara;font-size:16px;}table {font-family: candara;font-size:14px;border-collapse: collapse;width: 100%;}td, th {border: 3px solid #ff6666;text-align: left;padding: 8px;}tr:nth-child(even) {background-color: #dddddd;}.column {float: left;width: 33.33%;padding: 5px;}.row::after {content: "";clear:both;display: table;}}</style></head><body><p>Hello, ';
    htmlMailStr = htmlMailStr + ' <br><br> Here is your one pass key to login to your survey</p><br>';
    
    htmlMailStr = htmlMailStr + '<div><a href="' +this.medSurveyUrl;
    htmlMailStr = htmlMailStr + '" target="_blank">'+this.createRandomNumber()+'</a><p> Thanks, <br>MDS Registry Team</p><br></div><div class="row">';
    
    htmlMailStr = htmlMailStr + '<div class="column"><img src="http://cdn.mcauto-images-production.sendgrid.net/9196cd9d818e967a/d50b47e5-66d5-4726-8922-b162a930adb6/562x583.jpg" alt="Forest" style="width:100%"></div>'
    htmlMailStr = htmlMailStr + '<div class="column"><img src="http://cdn.mcauto-images-production.sendgrid.net/9196cd9d818e967a/1de2f700-06a5-4607-8f79-2784e5fc9795/640x320.png" alt="Snow" style="width:100%"></div>'
    htmlMailStr = htmlMailStr + '<div class="column"><img src="http://cdn.mcauto-images-production.sendgrid.net/9196cd9d818e967a/339e95fd-1996-418e-ac0d-9e83b46a9cc0/1651x1651.png" alt="Mountains" style="width:100%;"></div>';
    
    htmlMailStr = htmlMailStr + '</div></body></html>';

    var data = {
      "to": ["sasidhar.pasupuleti@live.com"],
      "cc": [],
      // "bcc": ["sasidhar.pasupuleti@bcm.edu"],
      // "bcc": ["pehlivan@bcm.edu", "Muharrem.Ak@bcm.edu"],
      "from": ["mdsresearchphysician@nrihub.org"],
      "subject": "Control Consent Receipt Confirmation",
      "message": htmlMailStr,
      "attachments" : []
    }
    this.fetchService.sendGmail(data).subscribe(res=>{
      this.openSnackBar("Survey link has been sent to your e-mail. Please check the junk/spam folder if you couldn't find it in your inbox", "Okay" )
      alert("Consent Submitted")
    })

  }

  surveys:any = []
  // openSurveysListDialog() {
  //   let email="sasidhar.pasupuleti@live.com"
  //   this.fetchService.getSurveysByEmail(email).subscribe(res=>{
  //     this.surveys = res;
  //     const dialogRef = this.dialog.open(SurveysListDialog, {
  //       width: '600px',
  //       data: { surveys: this.surveys }
  //     });
  //   })
  // }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 20000,
      panelClass : 'snackbar-appearance'
    });
  }
}


