import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class NfaService {
  private emailSource = new BehaviorSubject<string>('');
  currentEmail = this.emailSource.asObservable();
  constructor(private http:HttpClient, private router:Router) { }
  public serverUrl = environment.apiHost.replace("/api","");
  
  
  setEmail(email: string) {
    console.log(email)
    this.emailSource.next(email);
    localStorage.setItem("survey_user_email", email)
  }

  sendTokenEmail(email:string){

    return new Observable(observer => {
      this.http.post(this.serverUrl + 'token/createtokenmail',{'email':email})
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        });
    });
  }

  authenticate(id) {
    return this.http.post('/token/createtokenandjwt', id, { observe: 'response' })
      .toPromise()
      .then((res) => {
        let resp:any = <any>res
        const jwtToken = resp.body.token;
        const expiresAt = resp.headers.get('Expires-At');
        const cookieOptions = {
          expires: new Date(expiresAt),
          path: '/',
          sameSite: 'strict',
        };
        document.cookie = `jwtToken=${jwtToken};`;
        // this.router.navigate(['/dashboard']);
      });
  }

  authenticatewithjwt(id){
    console.log(id)
    return this.http.post(this.serverUrl + 'token/createtokenandjwt', {"uniq_id":id}, { observe: 'response' })
  }

  isAuthenticated() {
    console.log("is authenticated calling")
    return this.http.get<{authenticated: boolean}>(this.serverUrl + 'token/verifyjwt',{ withCredentials: true }).pipe(
      map(response => response.authenticated)
    );
  }
  
 logout() {
  this.http.get(this.serverUrl + 'token/logout').subscribe(() => {
    this.setEmail('');
    localStorage.removeItem("survey_user_email")
    this.router.navigate(['/one-pass']);
  });
}

refreshJWT() {
  return this.http.get<{authenticated: boolean}>(this.serverUrl + 'token/refreshjwt');
}

}
