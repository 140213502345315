import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router, RouterState, RouterStateSnapshot, RouterEvent, NavigationEnd } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public serverUrl = environment.apiHost;
  constructor(private httpClient: HttpClient,private route: ActivatedRoute,private router: Router) { }

  public login(userInfo){
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var userDet={ user_name: userInfo.user_name, password:userInfo.password}
    return this.httpClient.post(this.serverUrl +'authenticate/', userDet);
      

    // localStorage.setItem('ACCESS_TOKEN', "access_token");
  }

  public isLoggedIn(){
    return localStorage.getItem('ACCESS_TOKEN') !== null;

  }

  public logout(){
    localStorage.removeItem('ACCESS_TOKEN');
  }
  registerUser(user) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post(this.serverUrl +'register/', user);
  }
}
