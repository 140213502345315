import { Component, OnInit } from '@angular/core';
import { FetcherService } from 'app/fetcher.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-surveys-list',
  templateUrl: './surveys-list.component.html',
  styleUrls: ['./surveys-list.component.scss']
})
export class SurveysListComponent implements OnInit {

  constructor(private fetchService : FetcherService, private router: Router) { }

  surveys:any = []
  ngOnInit() {
    let user_email = localStorage.getItem('survey_user_email')
    console.log(user_email)
    //need to be removed after testing
    // user_email="sasidhar.pasupuleti@live.com"
    // localStorage.setItem('survey_user_email','sasidhar.pasupuleti@live.com')
    this.fetchService.getSurveysByEmail(user_email).subscribe(res=>{
      this.surveys = res
      console.log(this.surveys)
      // this.router.navigate(['/med-survey'], { queryParams: { value: JSON.stringify(this.surveys) }, skipLocationChange: true });
  })
  }
  
  createNewSurvey(){
    this.router.navigate(['/med-survey'])
  }

  selectSurvey(survey){
    console.log(survey)
    this.router.navigate(['/med-survey'], { queryParams: { value: JSON.stringify([survey]) }, skipLocationChange: true });

  }

  previewSubmittedSurvey(survey){
    console.log(survey)
    this.router.navigate(['/preview-survey'], { queryParams: { value: JSON.stringify([survey]) }, skipLocationChange: true });

  }
}
