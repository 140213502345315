import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
// import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SubjectsComponent } from './subjects/subjects.component';
import {MatCardModule} from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MedicationDialog, MedSurveyComponent, NonMedicalDialog } from './med-survey/med-survey.component';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import { AddMedComponent } from './med-survey/add-med/add-med.component';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { DatePipe } from '@angular/common';
import { MedGanttComponent } from './med-survey/med-gantt/med-gantt.component';
import { CustomDateFormatsDirective } from './med-survey/custom-date-format.directive'
import { YearMonthFormatDirective } from './med-survey/year-month-format.directive';
import { MedEffectPerSeizureComponent } from './med-survey/med-effect-per-seizure/med-effect-per-seizure.component';
import { OnePassComponent } from './one-pass/one-pass.component';
import { NfaService } from './one-pass/nfa.service';
import { SurveyAuthGuard } from './survey-auth.guard';
import { SurveysListComponent } from './one-pass/surveys-list/surveys-list.component';
import { PreviewSurveyComponent } from './med-survey/preview-survey/preview-survey.component';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDialogModule,
    MatChipsModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressBarModule
    // AgmCoreModule.forRoot({
    //   apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    // })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    CustomDateFormatsDirective,
    YearMonthFormatDirective,
    MedSurveyComponent,
    MedicationDialog,
    NonMedicalDialog,
    AddMedComponent,
    MedGanttComponent,
    MedEffectPerSeizureComponent,
    OnePassComponent,
    SurveysListComponent,
    PreviewSurveyComponent    

  ],
  exports:[CustomDateFormatsDirective,
    YearMonthFormatDirective],
  providers: [DatePipe,{provide:MAT_DATE_LOCALE, useValue: 'en-US'}, NfaService, SurveyAuthGuard],
  bootstrap: [AppComponent],
  entryComponents:[MedicationDialog,NonMedicalDialog]
})
export class AppModule { }
