import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MedSurveyComponent } from './med-survey/med-survey.component';
import { MedGanttComponent } from './med-survey/med-gantt/med-gantt.component';
import { MedEffectPerSeizureComponent } from './med-survey/med-effect-per-seizure/med-effect-per-seizure.component';
import { OnePassComponent } from './one-pass/one-pass.component';
import { SurveyAuthGuard } from './survey-auth.guard';
import { SurveysListComponent } from './one-pass/surveys-list/surveys-list.component';
import { PreviewSurveyComponent } from './med-survey/preview-survey/preview-survey.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path:'med-survey',
    component:MedSurveyComponent,
    pathMatch:'full',
    canActivate:[SurveyAuthGuard]
  },
  {
    path:'select-survey',
    component:SurveysListComponent,
    pathMatch:'full',
    canActivate:[SurveyAuthGuard]
  },
  {
    path:'preview-survey',
    component:PreviewSurveyComponent,
    pathMatch:'full',
    canActivate:[SurveyAuthGuard]
  },
  {
    path:'one-pass',
    component:OnePassComponent,
    pathMatch:'full'
  },
  { path: 'one-pass/:id', 
    component: OnePassComponent,
    // canActivate:[SurveyAuthGuard]
  },
  {
    path:'gantt',
    component:MedGanttComponent,
    pathMatch:'full'
  },
  {
    path:'subf',
    component:MedEffectPerSeizureComponent,
    pathMatch:'full'
  },
  { path: '**', redirectTo: '/main' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
