import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../authentication.service';
import { Router } from '@angular/router';


declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
  
  { path: '/consent', title: 'Consent',  icon: 'library_books', class: '' },
  { path: '/user-profile', title: 'Registration',  icon: 'person', class: '' },
  { path: '/genome', title: 'Genome Study',  icon: 'library_books', class: '' },
  { path: '/review-submit', title: 'Review And Submit',  icon: 'library_books', class: '' }
    // { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

export const LOGINROUTES: RouteInfo[] = [
  { path: '/survey', title: 'Survey',  icon: 'poll', class: '' },
  { path: '/mail', title: 'Mail',  icon: 'mail', class: '' },
  { path: '/subjects', title: 'Access List',  icon: 'library_books', class: '' }
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  providers:[AuthenticationService]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  loginMenuItems:any[];
  constructor(private authsvc : AuthenticationService,  private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.loginMenuItems = LOGINROUTES.filter(menuItem => menuItem);
    console.log(this.authsvc.isLoggedIn())
  }

  logout(){
    this.authsvc.logout();
    this.router.navigate(['/main'])
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
