import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FetcherService } from 'app/fetcher.service';

@Component({
  selector: 'app-preview-survey',
  templateUrl: './preview-survey.component.html',
  styleUrls: ['./preview-survey.component.scss']
})
export class PreviewSurveyComponent implements OnInit {

  constructor(private route: ActivatedRoute, private fs : FetcherService) { }
  private as_survey_id:any ;
  private demographics:any;
  private general_features:any;
  private medications:any;
  private interventions:any


  // Define column definitions for each table
  demographicsColumns: string[] = ['email_id', 'name', 'date_of_birth', 'race', 'country', 'state'];
  summaryColumns: string[] = ['survey_id', 'first_seizure_age_years', 'first_seizure_age_months', 'type_of_seizure', 'number_of_medications'];
  medicationsColumns: string[] = ['medicine_name', 'start_date', 'still_using', 'end_date', 'reason_for_medication', 'did_it_work', 'worked_for_which_seizures', 'side_effects', 'side_effects_list', 'others_specify', 'side_effect_comments'];
  interventionsColumns: string[] = ['names_of_medication', 'start_date', 'still_using', 'end_date', 'did_it_work', 'percent_of_reduction', 'how_long_did_it_work', 'average_duration_of_seizure_before_med', 'average_duration_of_seizure', 'frequency_of_seizure_before_med', 'frequency_of_seizure'];
  subMedicationColumns: string[] = ['percent_of_reduction', 'how_long_did_it_work', 'average_duration_of_seizure_before_med', 'average_duration_of_seizure', 'frequency_of_seizure_before_med', 'frequency_of_seizure'];

  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      if(Object.keys(params).length > 0){
        let survey_response = JSON.parse((params['value']))
        this.demographics = survey_response[0]
        this.as_survey_id = survey_response[0]["survey_id"]
        this.demographics = survey_response[0]
        console.log(survey_response)
        this.generalFeaturesInit(this.as_survey_id);
        this.patchMedHistory(this.as_survey_id);
        this.nonMedsInit(this.as_survey_id)
      }
      
    })
  }

  dataReady=false
  ngAfterViewInit(){
    this.dataReady=false
    this.dataReady = true
  }

  generalFeaturesInit(as_survey_id){
    this.fs.getGeneralFeaturesBySurveyId(as_survey_id).subscribe(res => {
    this.general_features = res[0]
      console.log(res)
    });
  }

  patchMedHistory(as_survey_id){
    this.fs.getCombinedMedicalHistory(as_survey_id).subscribe(med_history_res=>{
      this.medications = med_history_res
      console.log(med_history_res)
    })
  }

  nonMedsInit(as_survey_id){
    this.fs.getNonMedsForSurveyId(as_survey_id).subscribe(non_med_init_res=>{
      this.interventions = non_med_init_res
      console.log(non_med_init_res)
    })
  }

  getMedicationKeys(medication: any): string[] {
    if (!medication || !medication.med_worked_upon) {
      return [];
    }
  
    // Extract keys from the "med_worked_upon" object and return them as an array
    return Object.keys(medication.med_worked_upon);
  }

   // Functions to extract values for the tables
  getDemographicsData(key: string): string | null {
    return this.demographics.length > 0 ? this.demographics[0][key] : null;
  }

  getSummaryData(key: string): any {
    return this.general_features.length > 0 ? this.general_features[0][key] : null;
  }

  getMedicationData(key: string, index: number): any {
    return this.medications.length > index ? this.medications[index][key] : null;
  }

  // getMedicationKeys(index: number): string[] {
  //   return this.medications.length > index ? Object.keys(this.medications[index].med_worked_upon) : [];
  // }

  getInterventionData(key: string, index: number): any {
    return this.interventions.length > index ? this.interventions[index][key] : null;
  }

  getSubMedicationData(key: string, medication: any): any {
    if (medication.med_worked_upon && medication.med_worked_upon[key]) {
      return medication.med_worked_upon[key];
    }
    return null;
  }

}
